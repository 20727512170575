import React from "react";

import { Link } from "gatsby"

import Seo from "../components/seo";

import Logo from "../images/logos/kwd-logo2.jpg";
import Button from "../components/button";

import ImageGallery from "../components/ImageGallery"; // Assuming this component is correctly defined and functional

import t1 from "../images/works/familie-t/t1.jpg";
import t2 from "../images/works/familie-t/t2.jpg";
import t3 from "../images/works/familie-t/t3.jpg";
import t4 from "../images/works/familie-t/t4.jpg";
import t5 from "../images/works/familie-t/t5.jpg";
import t6 from "../images/works/familie-t/t6.jpg";
import t7 from "../images/works/familie-t/t7.jpg";
import t8 from "../images/works/familie-t/t8.jpg";
import t9 from "../images/works/familie-t/t9.jpg";
import t10 from "../images/works/familie-t/t10.jpg";
import t11 from "../images/works/familie-t/t11.jpg";
import t12 from "../images/works/familie-t/t12.jpg";

import h1 from "../images/works/familie-h/h1.jpg";
import h2 from "../images/works/familie-h/h2.jpg";
import h3 from "../images/works/familie-h/h3.jpg";
import h4 from "../images/works/familie-h/h4.jpg";
import h5 from "../images/works/familie-h/h5.jpg";
import h6 from "../images/works/familie-h/h6.jpg";
import h7 from "../images/works/familie-h/h7.jpg";
import h8 from "../images/works/familie-h/h8.jpg";
import h9 from "../images/works/familie-h/h9.jpg";
import h10 from "../images/works/familie-h/h10.jpg";
import h11 from "../images/works/familie-h/h11.jpg";

import g1 from "../images/works/familie-g/g1.jpg";
import g2 from "../images/works/familie-g/g2.jpg";
import g3 from "../images/works/familie-g/g3.jpg";
import g4 from "../images/works/familie-g/g4.jpg";
import g5 from "../images/works/familie-g/g5.jpg";

import k1 from "../images/works/familie-k/k1.jpg";
import k2 from "../images/works/familie-k/k2.jpg";
import k3 from "../images/works/familie-k/k3.jpg";
import k4 from "../images/works/familie-k/k4.jpg";
import k5 from "../images/works/familie-k/k5.jpg";
import k6 from "../images/works/familie-k/k6.jpg";
import k7 from "../images/works/familie-k/k7.jpg";
import k8 from "../images/works/familie-k/k8.jpg";
import k9 from "../images/works/familie-k/k9.jpg";
import k10 from "../images/works/familie-k/k10.jpg";
import k11 from "../images/works/familie-k/k11.jpg";
import k12 from "../images/works/familie-k/k12.jpg";
import k13 from "../images/works/familie-k/k13.jpg";

import b1 from "../images/works/familie-b/b1.jpg";
import b2 from "../images/works/familie-b/b2.jpg";
import b3 from "../images/works/familie-b/b3.jpg";
import b4 from "../images/works/familie-b/b4.jpg";
import b5 from "../images/works/familie-b/b5.jpg";
import b6 from "../images/works/familie-b/b6.jpg";
import b7 from "../images/works/familie-b/b7.jpg";
import b8 from "../images/works/familie-b/b8.jpg";
import b9 from "../images/works/familie-b/b9.jpg";
import b10 from "../images/works/familie-b/b10.jpg";
import b11 from "../images/works/familie-b/b11.jpg";
import b12 from "../images/works/familie-b/b12.jpg";
import b13 from "../images/works/familie-b/b13.jpg";
import b14 from "../images/works/familie-b/b14.jpg";
import b15 from "../images/works/familie-b/b15.jpg";

import r1 from "../images/works/familie-r/r1.jpg";
import r2 from "../images/works/familie-r/r2.jpg";
import r3 from "../images/works/familie-r/r3.jpg";
import r4 from "../images/works/familie-r/r4.jpg";
import r5 from "../images/works/familie-r/r5.jpg";
import r6 from "../images/works/familie-r/r6.jpg";
import r7 from "../images/works/familie-r/r7.jpg";

import hm1 from "../images/works/familie-hm/hm1.jpg";
import hm2 from "../images/works/familie-hm/hm2.jpg";
import hm3 from "../images/works/familie-hm/hm3.jpg";
import hm4 from "../images/works/familie-hm/hm4.jpg";
import hm5 from "../images/works/familie-hm/hm5.jpg";
import hm6 from "../images/works/familie-hm/hm6.jpg";
import hm7 from "../images/works/familie-hm/hm7.jpg";
import hm8 from "../images/works/familie-hm/hm8.jpg";
import hm9 from "../images/works/familie-hm/hm9.jpg";
import hm10 from "../images/works/familie-hm/hm10.jpg";
import hm11 from "../images/works/familie-hm/hm11.jpg";
import hm12 from "../images/works/familie-hm/hm12.jpg";
import hm13 from "../images/works/familie-hm/hm13.jpg";

import w1 from "../images/works/familie-w/w1.jpg";
import w2 from "../images/works/familie-w/w2.jpg";
import w3 from "../images/works/familie-w/w3.jpg";
import w4 from "../images/works/familie-w/w4.jpg";
import w5 from "../images/works/familie-w/w5.jpg";
import w6 from "../images/works/familie-w/w6.jpg";


const References = () => {
    <Seo/>

  const navigation = [
    { name: "Über uns", href: "/" },
    { name: "Leistungen", href: "/" },
    { name: "Unsere Arbeit", href: "/" },
  ];
  
   // Create image sets for each gallery
   const imageSets = [
      
    {
      title: "Küche Familie H.", // Add title for Gallery 2
      images: [
        { childImageSharp: { gatsbyImageData: { src: h1 } } },
        { childImageSharp: { gatsbyImageData: { src: h2 } } },
        { childImageSharp: { gatsbyImageData: { src: h3 } } },
        { childImageSharp: { gatsbyImageData: { src: h4 } } },
        { childImageSharp: { gatsbyImageData: { src: h5 } } },
        { childImageSharp: { gatsbyImageData: { src: h6 } } },
        { childImageSharp: { gatsbyImageData: { src: h7 } } },
        { childImageSharp: { gatsbyImageData: { src: h8 } } },
        { childImageSharp: { gatsbyImageData: { src: h9 } } },
        { childImageSharp: { gatsbyImageData: { src: h10 } } },
        { childImageSharp: { gatsbyImageData: { src: h11 } } },
      ],
      description: "Front mit durchlaufender Maserrichtung und versteckten Durchgangstüre. ​Front Weiß matt mit Dekor in Berglärche und ​Natursteinarbeitsplatte kombiniert.​", // Add your individual description
    },
    {
      title: "Küche Familie G.", // Add title for Gallery 3
      images: [
        { childImageSharp: { gatsbyImageData: { src: g1 } } },
        { childImageSharp: { gatsbyImageData: { src: g2 } } },
        { childImageSharp: { gatsbyImageData: { src: g3 } } },
        { childImageSharp: { gatsbyImageData: { src: g4 } } },
        { childImageSharp: { gatsbyImageData: { src: g5 } } },
      ],
      description: "Eine Küche mit Eichen-Echtholz-Elementen und integriertem Kachelofen mit Kochmöglichkeit.", // Add your individual description
    },
    {
      title: "Küche Familie K.", // Add title for Gallery 3
      images: [
        { childImageSharp: { gatsbyImageData: { src: k1 } } },
        { childImageSharp: { gatsbyImageData: { src: k2 } } },
        { childImageSharp: { gatsbyImageData: { src: k3 } } },
        { childImageSharp: { gatsbyImageData: { src: k4 } } },
        { childImageSharp: { gatsbyImageData: { src: k5 } } },
        { childImageSharp: { gatsbyImageData: { src: k6 } } },
        { childImageSharp: { gatsbyImageData: { src: k7 } } },
        { childImageSharp: { gatsbyImageData: { src: k8 } } },
        { childImageSharp: { gatsbyImageData: { src: k9 } } },
        { childImageSharp: { gatsbyImageData: { src: k10 } } },
        { childImageSharp: { gatsbyImageData: { src: k11 } } },
        { childImageSharp: { gatsbyImageData: { src: k12 } } },
        { childImageSharp: { gatsbyImageData: { src: k13 } } },
      ],
      description: "Insel mit Sitzbank und rundumlaufenden Griffmulden. Front Weiß matt mit Dekor in Wildeiche und Natursteinarbeitsplatte kombiniert.​", // Add your individual description
    },
    {
      title: "Küche Familie B.", // Add title for Gallery 3
      images: [
        { childImageSharp: { gatsbyImageData: { src: b1 } } },
        { childImageSharp: { gatsbyImageData: { src: b2 } } },
        { childImageSharp: { gatsbyImageData: { src: b3 } } },
        { childImageSharp: { gatsbyImageData: { src: b4 } } },
        { childImageSharp: { gatsbyImageData: { src: b5 } } },
        { childImageSharp: { gatsbyImageData: { src: b6 } } },
        { childImageSharp: { gatsbyImageData: { src: b7 } } },
        { childImageSharp: { gatsbyImageData: { src: b8 } } },
        { childImageSharp: { gatsbyImageData: { src: b9 } } },
        { childImageSharp: { gatsbyImageData: { src: b10 } } },
        { childImageSharp: { gatsbyImageData: { src: b11 } } },
        { childImageSharp: { gatsbyImageData: { src: b12 } } },
        { childImageSharp: { gatsbyImageData: { src: b13 } } },
        { childImageSharp: { gatsbyImageData: { src: b14 } } },
        { childImageSharp: { gatsbyImageData: { src: b15 } } },
      ],
      description: "Front weiß matt, mit Designelementen in Antikeiche.​ Rückwand mit gespaltenen Eichenlamellen​. Arbeitsplatte Naturstein mit Tropfrillen und versteckten Mülleimer.​", // Add your individual description
    },
    {
      title: "Küche Familie T.", // Add title for Gallery 1
      images: [
        { childImageSharp: { gatsbyImageData: { src: t1 } } },
        { childImageSharp: { gatsbyImageData: { src: t2 } } },
        { childImageSharp: { gatsbyImageData: { src: t3 } } },
        { childImageSharp: { gatsbyImageData: { src: t4 } } },
        { childImageSharp: { gatsbyImageData: { src: t5 } } },
        { childImageSharp: { gatsbyImageData: { src: t6 } } },
        { childImageSharp: { gatsbyImageData: { src: t7 } } },
        { childImageSharp: { gatsbyImageData: { src: t8 } } },
        { childImageSharp: { gatsbyImageData: { src: t9 } } },
        { childImageSharp: { gatsbyImageData: { src: t10 } } },
        { childImageSharp: { gatsbyImageData: { src: t11 } } },
        { childImageSharp: { gatsbyImageData: { src: t12 } } },
      ],
      description: "Front weiß matt in Kombination mit Eiche Echtholz, Natursteinplatte und Zierelementen in Altholz.​", // Add your individual description
    },
    {
      title: "Küche Familie R.", // Add title for Gallery 1
      images: [
        { childImageSharp: { gatsbyImageData: { src: r1 } } },
        { childImageSharp: { gatsbyImageData: { src: r2 } } },
        { childImageSharp: { gatsbyImageData: { src: r3 } } },
        { childImageSharp: { gatsbyImageData: { src: r4 } } },
        { childImageSharp: { gatsbyImageData: { src: r5 } } },
        { childImageSharp: { gatsbyImageData: { src: r6 } } },
        { childImageSharp: { gatsbyImageData: { src: r7 } } },
      ],
      description: "Küche mit architektonischen Barelement.​ Front Weiß matt mit Eiche Echtholz und Atlantis Natursteinarbeitsplatte kombiniert.​​", // Add your individual description
    },
    {
        title: "Küche Familie H.", // Add title for Gallery 1
        images: [
            { childImageSharp: { gatsbyImageData: { src: hm1 } } },
            { childImageSharp: { gatsbyImageData: { src: hm2 } } },
            { childImageSharp: { gatsbyImageData: { src: hm3 } } },
            { childImageSharp: { gatsbyImageData: { src: hm4 } } },
            { childImageSharp: { gatsbyImageData: { src: hm5 } } },
            { childImageSharp: { gatsbyImageData: { src: hm6 } } },
            { childImageSharp: { gatsbyImageData: { src: hm7 } } },
            { childImageSharp: { gatsbyImageData: { src: hm8 } } },
            { childImageSharp: { gatsbyImageData: { src: hm9 } } },
            { childImageSharp: { gatsbyImageData: { src: hm10 } } },
            { childImageSharp: { gatsbyImageData: { src: hm11 } } },
            { childImageSharp: { gatsbyImageData: { src: hm12 } } },
            { childImageSharp: { gatsbyImageData: { src: hm13 } } },
        ],
        description: "Die Fotos sprechen für sich.​​", // Add your individual description
      },
      {
        title: "Küche Familie W.", // Add title for Gallery 1
        images: [
          { childImageSharp: { gatsbyImageData: { src: w1 } } },
          { childImageSharp: { gatsbyImageData: { src: w2 } } },
          { childImageSharp: { gatsbyImageData: { src: w3 } } },
          { childImageSharp: { gatsbyImageData: { src: w4 } } },
          { childImageSharp: { gatsbyImageData: { src: w5 } } },
          { childImageSharp: { gatsbyImageData: { src: w6 } } },
        ],
        description: "Die Fotos sprechen für sich.​​", // Add your individual description
      },
    // ... add more image sets for the remaining galleries ...
  ];
  return (
    <div>
        
    <header className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
      <div className="container mx-auto">
        <div className="flex py-5 justify-between items-center">
          <div className="flex flex-row gap-8 items-center">
            {/* Link for scrolling to the top */}
            <Link to="/" spy={true} smooth={true} duration={500} className="cursor-pointer">
              <img className="" src={Logo} width={140} alt="Logo" />
            </Link>
          </div>
          <div className="flex flex-row gap-6">
            <div className="md:flex hidden flex-row gap-4 items-center">
              {navigation.map((item) => (
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  key={item.name}
                  to={item.href}
                  className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 cursor-pointer px-4"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <Link
              spy={true}
              smooth={true}
              duration={500}
              to="/#footer"
              className="inline-block cursor-pointer"
            >
              <Button label="KONTAKT" />
            </Link>
          </div>
        </div>
      </div>
      
    </header>
    
      <div className="container mx-auto">
        <div className="flex flex-col gap-12 lg:py-28 md:py-24 py-12" style={{ paddingTop: "105px" }}>
          <h3 className="font-display md:text-display-xl text-display-md font-normal pb-4 py-8">
            <span className="italic">Unserer Projekte</span> mit Liebe gemacht
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Map through each image set to create a gallery */}
            {imageSets.map((set, index) => (
              <div key={index}>
                <ImageGallery images={set.images} />
                <div className="flex flex-col self-stcdretch pt-6">
                  <h3 className="font-display text-display-md pb-4">{set.title}</h3>
                  <p className="text-body-lg font-light text-neutral-700">{set.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    
  );
}
  

export default References;
